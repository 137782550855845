<template>
  <div class="page">
    <div v-if="loaded" class="back">
      <van-loading
        size="80px"
        text-size="24px"
        color="#fff"
        type="circular"
        vertical
        >加载中...</van-loading
      >
    </div>
    <!-- 当前位置 -->
    <div class="toast" v-show="planList.length > 0 && !isShowSearch">
      <div class="toast-content">
        <div class="toast-l">
          <img src="./img/adress.png" alt="" />
        </div>
        <div class="toast-c">
          <div>当前位置</div>
          <div v-if="current.floor">
            {{
              current.floor
                ? `${current.build},${current.floor}`
                : `${current.build}`
            }}
          </div>
        </div>
        <div class="toast-r">
          <div class="line"></div>
          <div class="exit" @click="exit">
            <img src="./img/out.png" alt="" />
            <div>退出</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索 -->
    <div class="section-search" v-if="isShowSearch">
      <div class="search-flex">
        <div class="flex-right">
          <div class="right-input">
            <div class="input-wrap">
              <!-- 起点 -->
              <div class="input-current">
                <div class="dropdown">
                  <div class="select" @click="handelSelectStartType">
                    <span class="text">{{ form.startSelectText }}</span>
                    <img
                      :class="['arrow', { downArrow: isShowOptions }]"
                      src="./img/down-arrow.png"
                      alt=""
                    />
                  </div>
                  <transition name="fade-move">
                    <div class="options" v-if="isShowOptions">
                      <ul>
                        <li
                          :class="{
                            active: activeStatus.currentSelectItem == item,
                          }"
                          v-for="(item, index) in poiTypeOptions"
                          :key="index"
                          @click="handelSelect(item, index, 1)"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </div>
                  </transition>
                </div>
                <div class="circle"></div>
                <v-input
                  class="search-input"
                  ref="curInput"
                  v-model="form.curStart"
                  :maxlength="20"
                  placeholder="请输入起点地址"
                  @focus="curFocus"
                  @blur="curBlur"
                  @change="curChange"
                />
              </div>
              <div class="line"></div>
              <!-- 终点 -->
              <div class="input-arrive">
                <div class="dropdown">
                  <div class="select" @click="handelSelectEndType">
                    <span class="text">{{ form.endSelectText }}</span>
                    <img
                      :class="['arrow', { downArrow: isShowOptions1 }]"
                      src="./img/down-arrow.png"
                      alt=""
                    />
                  </div>
                  <transition name="fade-move">
                    <div class="options" v-if="isShowOptions1">
                      <ul>
                        <li
                          :class="{
                            active: activeStatus.currentSelectItem1 == index,
                          }"
                          v-for="(item, index) in poiTypeOptions"
                          :key="index"
                          @click="handelSelect(item, index, 2)"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </div>
                  </transition>
                </div>
                <div class="circle"></div>
                <v-input
                  :readonly="readonly"
                  class="search-input"
                  ref="arriveInput"
                  v-model="form.arrowEnd"
                  :maxlength="20"
                  placeholder="请输入终点地址"
                  @focus="arriveFocus"
                  @blur="arriveBlur"
                  @change="arriveChange"
                />
              </div>
            </div>
            <!-- 导航 -->
            <div class="input-icon" v-show="isShowIcon" @click="toPlan">
              <img src="./img/go_icon.png" alt="" />
            </div>
          </div>
          <!--  地下or地上-->
          <div class="right-tabs">
            <div
              :class="[
                'right-tabs--tab',
                item.value === curTab.value ? 'selectTab' : '',
              ]"
              v-for="(item, index) in tabs"
              :key="index"
              @click="chooseTab(item)"
            >
              <span>{{ item.text }}</span>
            </div>
          </div>
          <div class="right-tabs">
            <div
              :class="[
                'right-tabs--tab',
                item.value === curDrive.value ? 'selectTab' : '',
              ]"
              v-for="(item, index) in isDriveList"
              :key="index"
              @click="chooseType(item)"
            >
              <span>{{ item.text }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="search-options" v-show="isList">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="search-options--option"
            v-for="(option, oIndex) in listData"
            :key="oIndex"
            @click="chooseOption(option)"
          >
            <!-- <div class="image"><img src="./img/search_icon.png" alt="" /></div> -->
            <div :class="['image']">
              <img :src="imgSource[option.roomType]" alt="" />
            </div>
            <div class="address">
              <div>
                {{ option.name }}
              </div>
              <div>
                <span>
                  {{ option.buildName }}
                </span>
                <span v-if="option.roomType != 0">
                  - {{ roomTypeOptions[option.roomType] }}
                </span>
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </div>
    <!-- iframe -->
    <div class="section-iframe">
      <iframe
        id="planningIframe"
        class="bgIframe"
        :src="iframeUrl"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
    <!-- 指向 -->
    <div class="nav" v-show="!isShowSearch">
      <img
        src="./img/navicon.png"
        alt=""
        :style="`transform: rotate(${rotation}deg);`"
      />
    </div>
    <!-- 路线 -->
    <div class="footer" v-show="planList.length > 0 && !isShowSearch">
      <div class="footer-flex">
        <div class="warpper-flex">
          <div class="warpper-tip">
            <div class="warpper-tip--flex">
              <img src="./img/location.png" alt="" />
              <span>路线规划</span>
            </div>
            <div class="refresh" @click="refresh">
              <img src="./img/refresh.png" alt="" />
            </div>
          </div>
          <div class="warpper-content" ref="warperContent">
            <div
              :class="['section-list']"
              v-for="(item, index) in planList"
              :key="index"
              :id="`item_${index}`"
              @click="choosePlan(item, index)"
            >
              <div
                :class="['list-tip', currentIndex == index ? 'current' : '']"
              >
                <!-- <span v-if="!item.guide">楼层切换</span> -->
                <img
                  v-if="!item.guide"
                  :src="
                    item.isUp
                      ? require('./img/top.gif')
                      : require('./img/down.gif')
                  "
                  alt=""
                />
                <img v-else src="./img/run.gif" alt="" />
              </div>
              <div class="list-address">
                <span>{{ item.desc }}</span>
              </div>
              <!-- <div class="list-distance">{{ "--" }} 米</div> -->
              <div class="list-distance">第 {{ index + 1 }} 步</div>
            </div>
            <div
              class="tab-active"
              ref="tabActive"
              :style="{
                left: movePx,
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <guideCom @toEmit="toEmit" v-if="isGuide"></guideCom>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { Loading } from "vant";
import guideCom from "./component";
import { mapState } from "vuex";
import {
  stepURL,
  bimRoadUrl,
  poiType,
  saveOrUpdateDataEmbeddingPoint,
  getParamByVisitorId,
} from "./api";
import md5 from "js-md5";
// import { handleImg } from "@/utils/utils";
export default {
  components: {
    vanLoading: Loading,
    guideCom: guideCom,
  },
  data() {
    return {
      iframeUrl:"",
      isDriveList: [
        {
          text: "规划人行路线",
          value: false,
        },
        {
          text: "规划车行路线",
          value: true,
        },
      ],
      isGuide: true,
      roomTypeOptions: {
        "2-1": "办公室",
        "2-2": "车位",
        "2-3": "食堂",
        "2-4": "会议室",
        "3-4": "园区大门",
        "2-9": "其他",
        "2-5": "餐厅",
        "2-6": "卫生间",
        "2-7": "茶水室",
      },
      imgSource: {
        "2-1": require("./img/office.png"),
        "2-2": require("./img/park.png"),
        "2-3": require("./img/canteen.png"),
        "2-4": require("./img/conferenceRoom.png"),
        "3-4": require("./img/door.png"),
        "2-9": require("./img/other.png"),
        "2-5": require("./img/food.png"),
        "2-6": require("./img/wc.png"),
        "2-7": require("./img/water.png"),
      },
      currentIndex: 0,
      movePx: "",
      tabs: [
        {
          text: "规划地面路径",
          value: 0,
        },
        {
          text: "规划地库路径",
          value: -1,
        },
      ],
      curDrive: {
        text: "人行",
        value: false,
      },
      curTab: {
        text: "规划地面路径",
        value: 0,
      },

      rotation: 0,
      srcUrl: "https://yshgjyd.nbhq.gov.cn/jieneng/maze?sceneCode=maze",
      // isOption: false, // 是点击选项
      isShowIcon: false,
      isList: false,
      listData: [],
      planList: [],
      focus: undefined, // 1 前往 2 目的地
      curForm: {
        start: undefined, // 开始
        curStart: "",
        end: undefined, // 前往
        arrowEnd: "",
      },
      form: {
        start: undefined, // 开始
        startSelectText: "全部",
        curStart: "",
        end: undefined, // 前往
        endSelectText: "全部", // 前往
        arrowEnd: "",
        id: "14ea426f0f98421cab4f9ec01160c0d2",
        startBuildName: "",
        endBuildName: "",
      },
      timer: null,
      finished: true, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      curPage: 1,
      pageSize: 20,
      current: {},
      fromEnd: {},
      loaded: true,
      isShowSearch: true,
      poiTypeOptions: [],
      isShowOptions: false,
      isShowOptions1: false,
      spaceId: "",
      serviceZone: "",
      start: "",
      startName: "",
      activeStatus: {
        currentSelectItem: 0,
        currentSelectItem1: 0,
      },
      type: "visitor", //默认为大院工作人员，visitor为访客，worker为运维人员
      visitType: null,
      readonly: false,
      visitorId: "",
      door: "",
    };
  },
  watch: {
    form: {
      handler(val) {
        const { start, end, curStart } = val;
        if (!curStart) {
          this.form.start = undefined;
        }
        this.isShowIcon = start && end;
      },
      deep: true,
      immediate: true,
    },
    currentIndex: {
      handler(newValue) {
        // const element = document.getElementById(`item_${newValue}`);
        // this.movePx = element.offsetLeft + "px";
        this.movePx = newValue * 32 + "%";
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
    randomizedSrc() {
      const randomParam = Math.random();
      return this.srcUrl + "&random=" + randomParam;
    },
    // movePx() {
    //   return this.currentIndex * 32 + "%";
    // },
  },
  async created() {
    this.getPoiType();
    window.addEventListener("message", this.changeLoaded);
    let { start, door, startName, spaceId, serviceZone, type, visitorId } =
      this.$route.query;
    this.door = door;
    if (door) {
      this.form.startSelectText = "门岗";
      this.initStart();
    }
  },
  beforeDestroy() {
    window.removeEventListener("message", this.changeLoaded);
  },
  mounted() {
    this.initIframe()
  },
  methods: {
    initIframe() {
      // 应用码
      let appCode = "hzhc-maze";
      // 应用密钥
      let secret = "D3M_8819279b4a344d6fa6622c10402864e2";
      // 场景码，决定了加载哪个模型
      let sceneCode = "maze";
      // 时间，单位秒
      let tm = parseInt(new Date().getTime() / 1000);
      // 签名，拼接appCode，sceneCode，secret，tm的字符串，然后进行md5加密 md5(appCode + sceneCode + secret + tm)
      let sign = md5(appCode + sceneCode + secret + tm);
      this.iframeUrl =
        "https://yshgjyd.nbhq.gov.cn/jieneng/maze/?appCode=" +
        appCode +
        "&sceneCode=" +
        sceneCode +
        "&tm=" +
        tm +
        "&sign=" +
        sign;
    },
    //根据访客编码获取信息
    getParamByVisitorId(visitorId = "asdfghjkl") {
      this.$axios
        .get(getParamByVisitorId, {
          params: {
            visitorId: this.visitorId,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            this.visitType = data.visitType;
            this.readonly = true;
            //人行
            if (this.visitType == 1) {
              this.form.startSelectText = data.beginType; //类型
              this.form.end = data.endAddr; //结束地址id
              this.form.arrowEnd = data.endAddrName; //结束地址name
            } else {
              //车行
              this.form.startSelectText = "门岗"; //默认为门岗
              this.form.curStart = data.beginAddrName; //开始地址name
              this.form.start = data.beginAddr; //开始地址id
              this.form.end = data.endAddr; //结束地址id
              this.form.arrowEnd = data.endAddrName; //结束地址name
              this.curDrive = {
                text: "车行",
                value: true,
              };
            }
          }
        });
    },
    //选择起点类型
    handelSelectStartType() {
      //起点类型，如果为访客，起点默认只能选择门岗
      if (this.visitType == 1 || this.visitType == 2) {
        return;
      }
      this.isShowOptions = !this.isShowOptions;
    },
    //埋点数据
    saveOrUpdateDataEmbeddingPoint(dataType, startPoint, endPoint, userId) {
      const data = {
        dataType: this.type,
        startPoint: this.form.curStart,
        endPoint: this.form.arrowEnd,
      };
      this.$axios.post(saveOrUpdateDataEmbeddingPoint, data).then((res) => {
        if (res.code == 200) {
          console.log("res----->", res);
        }
      });
    },
    toEmit() {
      this.isGuide = false;
    },
    //选择终点类型
    handelSelectEndType() {
      //如果为访客，终点地址不可变，不可选
      if (this.visitType == 1 || this.visitType == 2) {
        return;
      }
      if (!this.form.start) {
        this.$toast({
          duration: 1000,
          message: "请选择起始位置",
        });
        return;
      }
      this.isShowOptions1 = !this.isShowOptions1;
    },
    handelSelect(item, index, type) {
      if (type == 1) {
        this.form.startSelectText = item;
        this.activeStatus.currentSelectItem = item;
        this.isShowOptions = false;
      } else {
        this.activeStatus.currentSelectItem1 = item;
        this.form.endSelectText = item;
        this.isShowOptions1 = false;
      }
      this.onRefresh();
    },
    //获取类型
    getPoiType() {
      this.$axios.get(poiType).then((res) => {
        if (res.code == 0) {
          this.poiTypeOptions = res.data;
        }
      });
    },
    //退出
    exit() {
      this.initData();
      this.isShowSearch = true;
      let iframe = document.getElementById("planningIframe");
      iframe.contentWindow.postMessage(
        // 重置功能，model为true则重置模型,camera为true重置视角，maze为true重置导航线路
        { type: "reset", data: { model: true, camera: true, maze: true } },
        "*"
      );
    },

    initData() {
      this.form = {
        startSelectText: "全部",
        curStart: "",
        end: undefined, // 前往
        endSelectText: "全部", // 前往
        arrowEnd: "",
        id: "14ea426f0f98421cab4f9ec01160c0d2",
        startBuildName: "",
        endBuildName: "",
      };
      (this.activeStatus = {
        currentSelectItem: 0,
        currentSelectItem1: 0,
      }),
        (this.curTab = {
          text: "规划地面路径",
          value: 0,
        });
      this.curDrive = {
        text: "人行",
        value: false,
      };
    },
    //重置
    refresh() {
      let iframe = document.getElementById("planningIframe");
      iframe.contentWindow.postMessage(
        // 重置功能，model为true则重置模型,camera为true重置视角，maze为true重置导航线路
        { type: "reset", data: { model: false, camera: true, maze: false } },
        "*"
      );
    },
    //初始位置
    async initStart() {
      let res = await this.$axios.get(bimRoadUrl, {
        params: {
          type: this.form.startSelectText,
          curPage: this.curPage,
          pageSize: this.pageSize,
          bpt: this.type,
        },
      });
      if (res.code == 0) {
        // this.listData = res.data.records;
        const option = res.data.records;
        option.forEach((ele) => {
          if (ele.name && ele.name.indexOf(this.door) != -1) {
            this.form.curStart = ele.name;
            this.form.start = ele.id;
          }
        });
      }
    },
    // 监听iframe资源是否加载完成，可以发送postmessage
    changeLoaded(ev) {
      if (ev.data && ev.data.type === "loaded") {
        this.loaded = false;
        //进行操作
      }
      if (ev.data && ev.data.type === "compass") {
        const { angle } = ev.data.payload;
        this.rotation = angle;
        //进行操作
      }
    },
    //选择路线
    choosePlan(item, index) {
      this.currentIndex = index;
      const element = document.getElementById(`item_${index}`);
      const container = this.$refs.warperContent;
      const scrollLeftNum =
        element.offsetLeft -
        container.offsetWidth / 2 +
        element.offsetWidth / 2;
      container.scrollLeft = scrollLeftNum;
      if (item.guide) {
        this.current = item;
        this.showIframe(this.current);
      }
    },
    // 选择地上地下
    chooseTab(tab) {
      this.curTab = tab;
      this.onRefresh();
    },
    chooseType(tab) {
      this.curDrive = tab;
      // this.onRefresh();
    },
    // 前往路线规划页面
    async toPlan() {
      const result = this.vaild();
      if (result) {
        //埋点
        await this.saveOrUpdateDataEmbeddingPoint();
        await this.getPlanList();
      }
    },
    // back() {},
    //防抖
    debounce(fn, wait) {
      if (this.timer !== null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(fn, wait);
    },
    // 当前位置-聚焦触发
    curFocus() {
      this.focus = 1;
      this.isList = true;
      this.onRefresh();
    },
    // 当前位置-失焦触发
    curBlur() {},
    // 当前位置-数据变化
    curChange() {
      this.debounce(this.onRefresh, 500);
    },
    arriveFocus() {
      if (this.readonly) {
        return;
      }
      if (!this.form.start) {
        this.$toast({
          duration: 1000,
          message: "请选择起始位置",
        });
        return;
      }
      this.focus = 2;
      this.isList = true;
      this.onRefresh();
    },
    arriveBlur() {},
    arriveChange() {
      this.debounce(this.onRefresh, 500);
    },
    chooseOption(item) {
      this.isList = false;
      if (this.focus == 1) {
        this.form.curStart = item.name;
        this.form.start = item.id;
        this.form.startBuildName = item.buildName;
      } else if (this.focus == 2) {
        if (!this.form.start) {
          this.$toast({
            duration: 1000,
            message: "请选择起始位置",
          });
          return;
        }
        this.form.arrowEnd = item.name;
        this.form.end = item.id;
        this.form.endBuildName = item.buildName;
        // this.showIframe();
        // this.getPlanList();
      }
    },
    showIframe(current) {
      let iframe = document.getElementById("planningIframe").contentWindow;
      const data = {
        start: this.form.start,
        end: this.form.end,
        id: this.form.id,
      };
      iframe.postMessage(
        // Object,start和end为POI的id，id为导航BIM的资源id
        { type: "maze", data: current || data },
        "*"
      );
    },

    // // 加载列表数据
    // async getPlanList() {
    //   this.planList = [];
    //   const res = await this.$axios.get(stepURL, {
    //     params: {
    //       start: this.form.start, // 开始
    //       end: this.form.end, // 前往
    //       priorityFloor: this.curTab.value, // 地下,  地上
    //       // priorityFloor: -1,
    //     },
    //   });
    //   if (res.code == 0 && res.data) {
    //     this.planList = res.data;
    //     this.current = this.planList.filter((v) => v.guide)[0];

    //   }

    // },
    // 加载列表数据
    getPlanList() {
      this.planList = [];
      this.$axios
        .get(stepURL, {
          params: {
            isDrive: this.curDrive.value,
            start: this.form.start, // 开始
            end: this.form.end, // 前往
            priorityFloor: this.curTab.value, // 地下,  地上
          },
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            this.planList = res.data;
            this.isShowSearch = false;
            this.currentIndex = this.planList.findIndex((v) => v.guide);
            this.current = this.planList.filter((v) => v.guide)[0];
            let iframe =
              document.getElementById("planningIframe").contentWindow;
            if (iframe && this.current.guide) {
              this.showIframe(this.current);
            }
          }
        });
    },
    onRefresh() {
      this.finishedText = "";
      let name = "";
      /* 请求接口前清空当前、到达的id */
      if (this.focus == 1) {
        name = this.form.curStart;
      } else if (this.focus == 2) {
        name = this.form.arrowEnd;
      }
      /* end */
      // this.finished = true;
      this.curPage = 1;
      this.listData = [];
      this.onLoad(name);
    },
    //检验
    vaild() {
      if (!this.form.start) {
        this.$toast({ message: "请输入正确的起点位置", duration: 1500 });
        return;
      } else if (!this.form.end) {
        this.$toast({ message: "请输入正确的终点位置", duration: 1500 });
        return;
      } else if (this.form.start === this.form.end) {
        this.$toast({ message: "起点等于终点，无需导航", duration: 1500 });
        return;
      }
      if (this.curDrive.text == "车行") {
        if (this.type == "staff") {
          if (this.form.startBuildName != "行政中心地面") {
            this.$toast({ message: "车行起点必须为门岗", duration: 1500 });
            return;
          }
          const buildName = ["行政中心地面", "行政中心5号楼地库"];
          if (buildName.includes(this.form.endBuildName)) {
            this.$toast({ message: "车行终点必须为楼", duration: 1500 });
            return;
          }
        }
      }

      return true;
    },
    // 加载列表数据
    onLoad(name) {
      this.$axios
        .get(bimRoadUrl, {
          params: {
            name,
            type:
              this.focus == 1
                ? this.form.startSelectText
                : this.form.endSelectText,
            curPage: this.curPage,
            pageSize: this.pageSize,
            bpt: this.type,
            // start: this.form.start, // 开始
            // end: this.form.end, // 前往
            // priorityFloor: this.curTab.value, // 地下,  地上
          },
        })
        .then((res) => {
          if (res.code != 0) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              this.listData = [];
              return;
            }
            if (res.code == 0 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  font-family: "PingFangSC-Medium", "Source_Han_Sans_SC_Bold";
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .guideCom {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
  .back {
    left: 0;
    top: 0;
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("./img/back.png") center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section-search {
    box-sizing: border-box;
    z-index: 9;
    width: 100%;
    background: url("./img/bg.png") no-repeat center center;
    background-size: 100% 594px;
    //  height: 484px;
    padding: 80px 26px 26px;

    position: absolute;
    left: 0;
    top: 0;
    .search-flex {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      padding: 26px 20px 22px 20px;
      background: #ffffff;
      border-radius: 16px;
      box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
      box-sizing: border-box;
      .flex-left {
        margin-right: 48px;
        img {
          // height: 26px;
          // width: 14px;
          height: 24px;
          width: 24px;
        }
      }
      .flex-right {
        flex: 1;
        .right-input {
          display: flex;
          align-items: center;
          background: #f5f8fb;
          width: 100%;
          height: 166px;
          border-radius: 16px;
          //  font-size: 28px;
          .input-wrap {
            display: flex;
            flex-direction: column;
            height: 100%;
            box-sizing: border-box;
            .input-current,
            .input-arrive {
              padding-left: 20px;
              flex: 1;
              display: flex;
              align-items: center;
              margin-right: 20px;
              box-sizing: border-box;
              .dropdown {
                // position: relative;
                .select {
                  display: flex;
                  align-items: center;
                  .text {
                    font-family: Arial, "Microsoft Yahei", "Helvetica Neue",
                      Helvetica, sans-serif;
                    min-width: 90px;
                    max-width: 90px;
                    font-size: 28px;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.5);
                    line-height: 40px;
                    margin: 0 24px 0 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                  .arrow {
                    width: 14px;
                    height: 10px;
                    transition: all 0.2s;
                    &.downArrow {
                      transform: rotate(180deg);
                    }
                  }
                }
                .fade-move-leave-active,
                .fade-move-enter-active {
                  transition: all 0.15s;
                }
                .fade-move-enter {
                  opacity: 1;
                  transform: translateY(-10px);
                }
                .fade-move-leave-to {
                  opacity: 0;
                  transform: translateY(-10px);
                }
                .options {
                  z-index: 8;
                  width: 30%;
                  max-height: 60vh;
                  overflow: hidden;
                  overflow-y: scroll;
                  position: absolute;
                  left: 6%;
                  background: #ffffff;
                  border-radius: 0 0 16px 16px;
                  box-shadow: 0px 0px 12px 10px rgba(207, 216, 231, 0.13);
                  li {
                    font-size: 28px;
                    padding: 20px 0 20px 30px;
                    &.active {
                      color: #549afe;
                    }
                  }
                }
              }
              .circle {
                margin: 0 20px 0 28px;
                width: 12px;
                height: 12px;
                background: #2d7ef8;
                border-radius: 50%;
              }
              .text {
                flex: 1;
                // text-overflow: ellipsis;
                white-space: nowrap;
                overflow-y: hidden;
                overflow-x: auto;
                line-height: 40px;
                padding: 0;
                font-size: 28px;
                color: rgba(0, 0, 0, 0.85);
              }
              .search-input {
                flex: 1;
                width: 100%;
                ::v-deep .van-cell {
                  width: 100%;
                  background-color: transparent;
                  line-height: 40px;
                  padding: 0;
                  font-size: 28px;
                  color: rgba(0, 0, 0, 0.85);
                }
              }
            }
            .line {
              margin: 0 20px 0 28px;
              width: 520px;
              height: 2px;
              background: #f1f1f1;
            }
            .input-arrive {
              .circle {
                background: #dd2a28;
              }
            }
          }
          .input-icon {
            img {
              width: 64px;
              height: 64px;
              vertical-align: middle;
            }
          }
        }

        .right-tabs {
          display: flex;
          margin-top: 22px;
          font-size: 26px;
          font-weight: 500;
          .right-tabs--tab {
            width: 202px;
            height: 54px;
            border-radius: 26px;
            text-align: center;
            line-height: 54px;
            // padding: 8px 14px;
            margin-right: 36px;
            color: rgba(0, 0, 0, 0.5);
            &.selectTab {
              background: linear-gradient(180deg, #549afe, #247dff);
              color: #ffffff;
            }
          }
        }
      }
    }
    .search-options {
      position: absolute;
      top: 460px;
      z-index: 7;
      overflow-y: auto;
      box-sizing: border-box;
      // margin-top: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 12px 10px rgba(207, 216, 231, 0.13);
      border-radius: 16px;
      padding: 0px 0 22px 0;
      box-sizing: border-box;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      width: calc(100% - 52px);
      height: calc(100vh - 485px);
      .search-options--option {
        display: flex;
        align-items: center;
        padding: 20px 20px 20px 30px;
        border-bottom: 2px solid #eeeeee;
        line-height: 40px;
        .image {
          width: 28px;
          height: 28x;
          margin: 0 18px 0 0;
          padding-top: 4px;
          opacity: 1;
          box-sizing: border-box;
          &.active {
            opacity: 0;
          }
          img {
            width: 28px;
            height: 28x;
          }
        }
        .address {
          word-wrap: break-word;
          word-break: break-all;
          div {
            &:first-child {
              font-weight: 500;
            }
            &:last-child {
              font-size: 24px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
    }
  }
  .section-iframe {
    flex: 1;
    .bgIframe {
      border: 0;
      vertical-align: top;
    }
  }

  .toast {
    position: fixed;
    left: 0;
    top: 7%;
    z-index: 33;
    width: 100%;

    box-sizing: border-box;
    padding: 0 14px;
    &.active {
      animation: fadeIn 0.3s ease 0s 1 normal;
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(-100px);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .toast-content {
      padding: 36px 34px 34px 42px;
      width: 100%;
      box-sizing: border-box;
      background: #212c49;
      box-shadow: 0px 4px 10px 6px rgba(144, 144, 144, 0.5);
      border-radius: 8px;
      display: flex;
      align-items: center;
      .toast-l {
        margin-right: 32px;
        img {
          width: 40px;
          height: 48px;
          vertical-align: middle;
        }
      }
      .toast-c {
        padding-right: 20px;
        box-sizing: border-box;
        flex: 1;
        div {
          width: 100%;
          &:first-child {
            font-size: 28px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.5);
          }
          &:last-child {
            font-size: 32px;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
      .toast-r {
        display: flex;
        align-items: center;
        .line {
          margin-right: 22px;
          width: 2px;
          height: 52px;
          background-color: rgba(255, 255, 255, 0.43);
        }
        .exit {
          display: flex;
          flex-direction: column;
          justify-content: center;
          img {
            width: 32px;
            height: 32px;
            vertical-align: middle;
            margin: auto auto 4px auto;
          }
          font-size: 24px;
          font-weight: 400;
          color: #ffffff;
          line-height: 34px;
        }
      }
    }
  }
  .nav {
    position: fixed;
    left: 2%;
    top: 20%;
    z-index: 2;
    img {
      width: 90px;
      height: 90px;
      transition: transform 0.5s ease; /* 使用CSS过渡效果实现平滑旋转 */
    }
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    background: linear-gradient(180deg, #e9f1fb 0%, #ffffff 100%);
    box-shadow: 0px 4px 10px 6px rgba(218, 218, 218, 0.5);
    border-radius: 16px;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    .footer-flex {
      position: relative;
      height: 100%;
      margin: 0 14px;
      background: #ffffff;
      .warpper-flex {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        .warpper-tip {
          display: flex;
          align-items: center;
          justify-content: space-between;

          margin-bottom: 16px;

          .warpper-tip--flex {
            box-shadow: 0px 4px 22px 0px #e5e5e5;
            border-radius: 20px;
            font-size: 24px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            background: #ffffff;
            padding: 20px 14px;
            display: flex;
            align-items: center;
            img {
              height: 30px;
              width: 30px;
              margin-right: 6px;
              vertical-align: middle;
            }
            span {
              vertical-align: middle;
            }
          }
          .refresh {
            width: 70px;
            height: 70px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            box-shadow: 0px 4px 10px 6px rgba(218, 218, 218, 0.5);
            border-radius: 16px;
            padding: 20px;
            img {
              width: 32px;
              height: 32px;
            }
          }
        }
        .warpper-content {
          width: 100%;
          position: relative;
          box-sizing: border-box;
          min-height: 240px;
          background: linear-gradient(180deg, #e9f1fb 0%, #ffffff 100%);
          box-shadow: 0px 4px 10px 6px rgba(218, 218, 218, 0.5);
          display: flex;
          border-radius: 16px;
          overflow-x: auto;
          overflow-y: hidden;
          scroll-behavior: smooth;
          -webkit-overflow-scrolling: touch;
          z-index: 10;
          .section-list {
            z-index: 2;
            box-sizing: border-box;
            width: 32%;
            min-width: 32%;
            color: rgba(229, 229, 229, 0.5);
            padding: 22px 26px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: rgba(0, 0, 0, 0.85);
            // &:not(:first-child) {
            //   margin-left: 2%;
            // }
            .list-tip {
              opacity: 0;
              font-weight: 500;
              height: 34px;
              font-size: 24px;
              &.current {
                opacity: 1;
                color: #237dff;
              }
              img {
                width: 60px;
                height: 60px;
              }
            }
            .list-address {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-weight: 600;
              font-size: 26px;
              // margin: 20px 0;
            }
            .list-distance {
              font-size: 24px;
              color: #237dff;
            }
          }
          .tab-active {
            z-index: 1;
            width: 32%;
            height: 100%;
            position: absolute;
            bottom: 0;
            // left: 0;
            background: #ffffff;
            transition: left 0.2s;
          }
        }
      }
    }
  }
}
</style>
